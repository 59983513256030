import React from 'react'
import Layout from '../layouts'
import { graphql } from 'gatsby';
import { HTMLContent } from '../components/Content';
import { Helmet } from 'react-helmet';
const DefaultPage =(props)=>{
  console.log(props,'default page')
    return (<Layout page={'formal'}>
      
        <Helmet>
        <title>{`${props.data.markdownRemark.frontmatter.title} | JDSolutions`}</title>
        <meta property="og:title" content={`${props.data.markdownRemark.frontmatter.title} | JDSolutions`}/>
        <meta property="og:type" content="webcontent" />
        </Helmet>
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-cell about-section ">
        <div className="row-fluid-wrapper row-depth-1 row-number-2  ">
        <div className="span12 widget-span widget-type-cell wrapper ">
        <div className="row-fluid-wrapper row-depth-1 row-number-3  ">
        <div className="row-fluid ">
            <HTMLContent content={props.data.markdownRemark.html}/>
            </div>
            </div>
            </div>
            </div>
            </div>
        </div>
        </Layout>)
}


export const pageQuery = graphql`
query PageByID($id : String!) {
    markdownRemark(id: { eq: $id }) {
        id
        html
        excerpt
        frontmatter {
          title
        }
      }
    }
    
  `
  export default DefaultPage;